import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import './SnapCircle.css';
import backGround from "../assets/img/pattern.png";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Data = [
  {
    label: {
      heading: "Consultancy",
      details: "We start by comprehending your specific needs and evaluating your business environment. Together, we devise tailored strategies to address challenges and achieve your goals.",
      points: {
        "ERP Implementation": {
          sub_point1: 'Microsoft Dynamics 365',
          sub_point2: 'Microsoft Business Central 365'
        },
        "Cyber Security": {},
        "Digital Transformation": {},
        "Business Process": {},
        "Cloud Services": {}
      }
    }
  },
  {
    label: {
      heading: "Software Development",
      details: "After gathering insights, we utilize our Software Development Solutions to create a scalable, secure, and flexible digital business infrastructure. Our coding experts then develop customized solutions that seamlessly integrate with your existing IT systems, automating essential tasks and workflows.",
      points: {
        "Mobile Application": {},
        "Quality Insurance": {},
        "DevOps": {},
        "Customization": {},
        "Workflows": {},
        "Testing Automations": {},
        "Website Development": {}
      }
    }
  },
  {
    label: {
      heading: "Resource Augmentation",
      details: "Acquire skilled professionals tailored to your needs, ensuring project timelines and budgets are met without the constraints of permanent hires. Discover the advantages of staff augmentation in below domains",
      points: {
        "Software Development": {},
        "Microsoft Dynamics": {},
        "Microsoft Power Platform": {},
        "Data AI and Cloud": {},
        "Cybersecurity": {}
      }
    }
  },
  {
    label: {
      heading: "Customer Support",
      details: "Our commitment goes beyond implementation. We offer continuous support to meet evolving needs, ensuring you consistently leverage the potential of Microsoft solutions for sustained success.",
      points: {
        "ERP end to end support": {},
        "Training": {},
        "Maintenance": {}
      }
    }
  }
];

export const SnapCircle = () => {
  const circleRef = useRef(null);
  const detailRefs = useRef([]);
  const [idx, setIdx] = useState(null);
  const rotationRef = useRef(0);
  const windowWidth = window.innerWidth;

  
  useGSAP(() => {
    if(windowWidth > 680){
      ScrollTrigger.create({
        start: 'top 10%',
        endTrigger: '.nextSection',
        end: '.bottom bottom',
        // markers:true,
        pin: '.circle',
      });
  
      ScrollTrigger.create({
        start: 'top 10%',
        endTrigger: '.nextSection',
        end: '.bottom bottom',
        pin: '.background',
      });
      ScrollTrigger.create({
        trigger: '.content',
        start: 'top 10%',
        end: 'bottom bottom',
        snap: {
          snapTo: 0.33,
          duration: { min: 0.2, max: 0.5 },
          ease: 'power1.inOut',
        },
      });
  
      detailRefs.current.forEach((detail, index) => {
        ScrollTrigger.create({
          trigger: detail,
          start: 'top 10%',
          end: 'bottom bottom',
          onEnter: () => {
            highlightListItem(index);
            flipImage();
          },
          onLeaveBack: () => {
            highlightListItem(index - 1);
            flipImage();
          },
        });
      });
  
      const flipImage = () => {
        if (rotationRef.current >= 360) rotationRef.current = 0;
        rotationRef.current += 180;
        gsap.to('.background', {
          rotationX: rotationRef.current,
          ease: 'power2.inOut',
          duration: 1,
          transformOrigin: 'center center',
        });
      };
    }
    else {
      const sections = gsap.utils.toArray('.details-snap');
      ScrollTrigger.create({
        start: 'top 10%',
        endTrigger: '.nextSection',
        end: '.bottom 10%',
        // markers:true,
        pin: '.circle',
      });
      ScrollTrigger.create({
        trigger: '.content',
        start: 'top 10%',
        end: 'bottom bottom',
        // markers:true,
        scrub:1,
        snap: {
          snapTo: 1/(sections.length - 1),
          duration: { min: 0.2, max: 0.5 },
          ease: 'power1.inOut',
        },
      });
      sections.forEach((section, i) => {
        gsap.fromTo(section, 
          { opacity: 0 },  // Start with opacity 0
          { 
            opacity: 1,  // Fade to opacity 1
            duration: 1, // Duration of fade-in
            scrollTrigger: {
              trigger: section,
              start: "top 80%", // When the top of the section hits 80% of the viewport height
              end: "top 9%",   // When the top of the section hits 30% of the viewport height
              scrub: true,      // Smoothly scrubs the animation
              markers: false,   // Set to true to see the start and end markers in the viewport
              onEnter: () => gsap.to(section, { opacity: 1, duration: 1 }), // Fade in on enter
              onLeave: () => gsap.to(section, { opacity: 0, duration: 1 }), // Fade out on leave
              onEnterBack: () => gsap.to(section, { opacity: 1, duration: 1 }), // Fade in on re-enter
              onLeaveBack: () => gsap.to(section, { opacity: 0, duration: 1 }) // Fade out on leave back
            }
          }
        );
      });
    }
    
    
  }, []);

  const highlightListItem = (index) => {
    setIdx(index);
  };

  useEffect(() => {
    const listItems = circleRef.current.querySelectorAll('.circle-content .list');
    listItems.forEach((item, index) => {
      if (idx === index) {
        item.classList.add('highlight');
      } else {
        item.classList.remove('highlight');
      }
    });
  }, [idx]);

  useEffect(() => {
    const listItems = document.querySelectorAll('.circle-content .list');
    for (let index = 0; index < listItems.length; index++) {
      if (index % 2 === 0) {
        listItems[index].classList.add('right-margin');
      } else {
        listItems[index].classList.add('left-margin');
      }
    }
  }, []);

  const handleClick = (index) => {
    gsap.to(window, {
      scrollTo: { y: detailRefs.current[index] , offsetY : 65},
      duration: 1,
      ease: 'power2.inOut'
    });
    highlightListItem(index);
  };

  return (
    <>
      <div className="Snap-Container">
        <div className="background">
          <img src={backGround} alt="" className='circleImage'/>
        </div>
        <div className="circle" ref={circleRef}>
          <div className="circle-content">
            {Data.map((data, index) => (
              <>
              <div
                key={index}
                className='list inter-headings-h3'
                onClick={() => handleClick(index)}
              >
                {data.label.heading}
              </div>
              {index < Data.length - 1 && <hr />}
              </>
            ))}
          </div>
        </div>
        <div className="content">
          {Data.map((data, index) => (
            <div
              key={data.label.heading}
              className="details-snap"
              ref={(el) => (detailRefs.current[index] = el)}
            >
              <h3 className='inter-headings-h3'>{data.label.heading}</h3>
              <p className='inter-details'>{data.label.details}</p>
              <ul className='points-snap'>
                {Object.keys(data.label.points).map((point, i) => (
                  <li key={i}>
                    {point}
                    <ul className='sub-points'>
                      {Object.keys(data.label.points[point]).map((subPoint, j) => (
                        <li key={j} className='inter-details'>{data.label.points[point][subPoint]}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="nextSection"></div>
    </>
  );
};

export default SnapCircle;
