import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './index.css';
import App from './App';
import CloudServices from './pages/CloudServices';
import DataAIServices from './pages/DataAIServices';
import DevOpsServices from './pages/DevOpsServices';
import ExperienceDesign from './pages/ExperienceDesign';
import MicroSoftBusiness from './pages/MicrosoftBusiness';
import MicroSoftDynamics from './pages/MicrosoftDynamics';
import MobileApplication from './pages/MobileApplication';
import QualityAssurance from './pages/QualityAssurance';
import ResourceAugmentation from './pages/ResourceAugmentation';
import SoftwareDevelopment from './pages/SoftwareDevelopment';
import CLoudWinkel from './pages/CloudWinkel';
import Training from './pages/Training';
import Support from './pages/Support';
import AboutPage from './pages/AboutPage';
import CyberSecurity from './pages/CyberSecurity';
import InfoPage from './pages/InfoPage';
import SupplyChainManagement from './pages/SupplyChainManagement';
import FinanceManagement from './pages/FinanceManagement';
import RetailManagement from './pages/RetailManagement';
import ProductionManagement from './pages/ProductionManagement';
import CareerPage from "./pages/CareerPage";

import reportWebVitals from './reportWebVitals';

const basename = ''; // Update this with your actual subdirectory if needed

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Router basename={basename}> */}
    <Router basename={basename}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="CloudServices" element={<CloudServices />} />
        <Route path="DataAIServices" element={<DataAIServices />} />
        <Route path="DevOpsServices" element={<DevOpsServices />} />
        <Route path="ExperienceDesign" element={<ExperienceDesign />} />
        <Route path="BusinessCentral" element={<MicroSoftBusiness />} />
        <Route path="MicrosoftDynamics" element={<MicroSoftDynamics />} />
        <Route path="MobileApplication" element={<MobileApplication />} />
        <Route path="QualityAssurance" element={<QualityAssurance />} />
        <Route path="ResourceAugmentation" element={<ResourceAugmentation />} />
        <Route path="SoftwareDevelopment" element={<SoftwareDevelopment />} />
        <Route path="CloudWinkel" element={<CLoudWinkel />} />
        <Route path="Training" element={<Training />} />
        <Route path="Support" element={<Support />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="cyberSecurity" element={<CyberSecurity />} />
        <Route path="infoPage" element={<InfoPage />} />
        <Route path="supplyChainManagementTraining" element={<SupplyChainManagement />} />
        <Route path="retailManagementTraining" element={<RetailManagement />} />
        <Route path="financeManagementTraining" element={<FinanceManagement />} />
        <Route path="productManagementTraining" element={<ProductionManagement />} />
        <Route path="careerPage" element={<CareerPage />}/>
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
