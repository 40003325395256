import React from "react";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";


import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faArrowUpRightDots } from "@fortawesome/free-solid-svg-icons";

import { faRulerCombined } from "@fortawesome/free-solid-svg-icons";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

import mainBanner from "../assets/img/main_banners/MicrosoftDynamics360page.webp";

import DetailsImg from "../assets/img/MicrosftDynamics360/Unlockyourbusinessfull.jpg";

import ServicePointImgBack from "../assets/img/MicrosftDynamics360/Dynamics365FinanceandOperationsmodules.jpg";

import servicePointImg1 from "../assets/img/MicrosftDynamics360/Finance.jpg";
import servicePointImg2 from "../assets/img/MicrosftDynamics360/supplychain.jpg";
import servicePointImg3 from "../assets/img/MicrosftDynamics360/Retail.jpg";
import servicePointImg4 from "../assets/img/MicrosftDynamics360/Manufacturing.jpg";
import servicePointImg5 from "../assets/img/MicrosftDynamics360/HumanResource.jpg";

import supportDetailImg from "../assets/img/MicrosftDynamics360/Stayahead.jpg";



import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import ScrollToTop from "../components/ScrollToTop";
const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const ServicePointsSelect = lazy(() => import('../components/ServicePointsSelect'));
const BoxDetails = lazy(() => import('../components/BoxDetails'));
const Solutions = lazy(() => import('../components/Solutions'));



const mainContent = {
  heading: "Microsoft Dynamics 365",
  title:
    "Embrace the power of Microsoft Dynamics 365 to drive innovation forward.",
  para: "Embrace adaptability and innovation by seamlessly connecting data, processes, and people. Leverage intelligent applications to enhance agility and future-proof your business.",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Unlock your business's full potential with Microsoft Dynamics 365.",
  para: "This powerful platform integrates a portfolio of intelligent business applications, enabling seamless collaboration and agility across your organization. By bringing together people, processes, and data, Dynamics 365 empowers you to transform information into actionable insights, driving successful outcomes for your business.",
  image1: DetailsImg,
  points: [],
};

const boxData = {
  heading_1: {
    text: "Impact of",
    light: true,
  },
  heading_2: {
    text: "Dynamics 365 Finance and Operations",
    light: false,
  },
  cards: [
    {
      title: "Enhanced data security and accessibility",
      icon: faRulerCombined,
      details1:
        "The cloud-based Microsoft Dynamics 365 Finance and Operations, powered by Azure, offers robust data security with integrated disaster recovery and 24/7 support. This platform protects your critical business information and ensures seamless accessibility. Its cross-platform user interface allows users to access the system from any location and device, enhancing productivity and collaboration. This flexibility enables your teams to work efficiently and make informed decisions in real-time, regardless of where they are.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Budget-friendly solution",
      icon: faCoins,
      details1:
        "Dynamics 365 Finance and Operations optimizes business operations by eliminating redundant resources and reducing costs through digitalization. It streamlines processes, allowing organizations to operate more efficiently while minimizing waste. The platform simplifies customization and integration with existing systems, lowering overall expenses and training costs. This enables organizations to focus on strategic initiatives and drive growth while keeping operational expenses to a minimum.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Optimized business operations",
      icon: faCloud,
      details1:
        "Microsoft Dynamics 365's cloud-native ERP solution is designed for enterprises across various sectors, offering tailored applications that streamline operations and enhance efficiency. By integrating core functions like finance, supply chain, sales, and customer service, the platform enables seamless collaboration and quick adaptation to market changes. With real-time insights and analytics, organizations can make informed decisions, optimize resources, and drive growth while delivering exceptional customer experiences.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Improved visibility and control",
      icon: faLightbulb,
      details1:
        "Microsoft Dynamics 365 Finance and Operations (F&O) delivers data-backed insights by integrating intelligence from key business sectors. This enhances efficiency and optimizes workflows across finance, sales, procurement, and inventory. Leaders gain real-time access to information through intuitive dashboards, enabling informed decision-making and demand forecasting. Ultimately, Dynamics 365 F&O helps organizations achieve operational excellence and maintain competitiveness.",
      points: [],
      details2: "",
      link: "",
    },
  ],
};


const solutionData = {
  title_styled: "NFIT Solutions",
  title: "INDUSTRY EDGE",
  points: [
    {
      heading: "Industry strength",
      description:
        "With vast industry experience, our certified Microsoft Dynamics 365 consultants have successfully executed more than 20 complex ERP projects in various fields, including retail, CPG, and manufacturing. We excel in transforming growing businesses and supporting enterprise growth.",
    },
    {
      heading: "Customer-first principle",
      description:
        "Customer satisfaction is a cornerstone of our business practices. We adopt a structured project management methodology tailored to the complexity of the business environment and operations, offering 24/7 customer support and ensuring transparency with our clients.",
    },
    {
      heading: "D365 Finance and Operations experts",
      description:
        "Boasting a team of over 20 Microsoft Dynamics certified developers, NFIT Solutions possesses the technical expertise and experience to effectively execute projects involving implementation, integration, customization, migration, and consulting services on a global scale.",
    },
    {
      heading: "Effective industry solutions",
      description:
        "At NFIT Solutions, we deliver tailored, ready-to-deploy solutions based on Dynamics 365, designed to expedite our clients' digital transformation journey. Our mission is to enhance business excellence for our valued clients by offering solutions that tackle their primary industry challenges.",
    },
  ],
};

const servicePoints = {
  // heading: "Dynamics 365 Finance and Operations modules",
  // heading_style: "",
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Dynamics 365 Finance and Operations modules",
    light: false,
  },
  backImg: ServicePointImgBack,
  points: [
    {
      title: "Finance",
      image: servicePointImg1,
      content:
        "Improve your business efficiency and respond swiftly to evolving market conditions by refining your financial processes and streamlining operations. Gain real-time insights and analytics for informed decision-making.",
    },
    {
      title: "Supply Chain",
      image: servicePointImg2,
      content:
        "Maintain a competitive edge in your supply chain operations by harnessing robust capabilities such as real-time visibility, adaptable planning, and advanced analytics. Gain valuable insights and stay ahead of the competition, avoiding disruptions.",
    },
    {
      title: "Retail",
      image: servicePointImg3,
      content:
        "Keep ahead in your Retail industry and prevent interruptions by utilizing robust features, such as instant visibility, adaptable planning, and state-of-the-art analytics, to gain valuable perspectives and remain at the forefront.",
    },
    {
      title: "Manufacturing",
      image: servicePointImg4,
      content:
        "Maintain your competitive edge in manufacturing and minimize disruptions by harnessing robust features such as live tracking, adaptable planning, and advanced analytics. Gain valuable insights and stay ahead of the competition.",
    },
    {
      title: "Human Resource",
      image: servicePointImg5,
      content:
        "Implement automation in your HR workflows, elevate employee involvement, and empower data-informed decisions using the rich functionalities of Dynamics 365 Human Resources. Empower your organization to increase productivity.",
    },
  ],
};


const supportData = {
  heading:
    "Stay ahead of the competition with proactive Microsoft Dynamics 365 Support.",
  para: "Ensure your business stays operational and get the most out of your Dynamics 365 solutions with NFIT Solutions, your Dynamics 365 Managed Services and Support Partner. We empower you to leverage the full potential of intelligent Microsoft Business Applications suite solutions, including ERP and CRM. With our wealth of experience, our committed and adaptable support nurtures your strong solution, guaranteeing smooth business processes and encouraging ongoing business expansion.",
  image1: supportDetailImg,
  points: [],
};

const supportBoxData = {
  heading_1: {
    text: "Enhance your Dynamics 365 experience with our robust support system.",
    light: false,
  },
  heading_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      title: "Technical Support",
      icon: faHeadset,
      details1:
        "With our Microsoft Dynamics technical assistance, you can streamline your business processes, effectively address technical challenges, and configure advanced functionalities tailored to your unique needs. Our expert team provides comprehensive support, helping you optimize the use of Dynamics 365 to enhance productivity and efficiency. We work closely with you to identify areas for improvement, implement best practices, and ensure seamless integration with existing systems. By maximizing the capabilities of Dynamics 365, we enable you to achieve a higher return on investment (ROI) and drive continuous growth for your organization. Our commitment to delivering tailored solutions empowers your teams to leverage the full potential of Microsoft Dynamics, ultimately enhancing overall performance and customer satisfaction.",
      points: [],
      details2: "",
      link: "softwareDevelopment",
    },
    {
      title: "Functional support",
      icon: faCircleInfo,
      details1:
        "Customize and set up Dynamics 365 modules to align with your business requirements, enhancing operational efficiency and user engagement. We begin with a thorough analysis of your processes to tailor the ERP and CRM modules to your unique workflows. Our expert team ensures seamless integration with existing systems, facilitating smooth operations across departments. By configuring advanced functionalities and automating processes, we boost user productivity and engagement. With training and ongoing support, we empower your team to leverage the full capabilities of Dynamics 365, ensuring optimal performance that supports your strategic objectives.",
      points: [],
      details2: "",
      link: "microsoftDynamics",
    },
    {
      title: "System administration and monitoring",
      icon: faUserTie,
      details1:
        "We provide comprehensive management of your Dynamics 365 (D365) environment, covering security, performance monitoring, and issue resolution. Our dedicated team implements advanced security measures to protect your data and ensure compliance. We continuously monitor system performance, identifying optimization opportunities and preventing potential issues before they escalate. In the event of any problems, our support team quickly troubleshoots and resolves them, minimizing downtime. By managing your D365 environment holistically, we help maximize the platform's capabilities and enhance user experience, allowing you to focus on your core business activities.",
      points: [],
      details2: "",
      link: "microsoftBusiness",
    },
    {
      title: "Customization and configuration",
      icon: faTable,
      details1:
        "We customize your Dynamics 365 ERP and CRM systems with bespoke workflows, integrations, and tailored solutions to meet your unique business needs. Our process includes assessing your operations to design efficient workflows and ensure seamless integration with existing systems for unified data flow. We enhance user experience through custom solutions and intuitive interface modifications, along with comprehensive training and support. This personalized approach enables greater operational efficiency, improved customer engagement, and supports business growth.",
      points: [],
      details2: "",
      link: "CloudServices",
    },
    {
      title: "Training and knowledge transfer",
      icon: faGears,
      details1:
        "Our Microsoft Dynamics 365 Support Services include tailored training and ongoing support to optimize system utilization. We assess your team's needs to create customized training materials and deliver sessions through workshops, virtual training, or one-on-one coaching. Additionally, we provide continuous assistance via a dedicated help desk and regular check-ins to address challenges. This combined focus on training and support empowers your team to maximize the benefits of Microsoft Dynamics 365, enhancing productivity and confidence in using the system.",
      points: [],
      details2: "",
      link: "dataAIServices",
    },
    {
      title: "Integration support",
      icon: faNetworkWired,
      details1:
        "We seamlessly integrate Dynamics 365 with the Microsoft technology stack and third-party applications, creating a cohesive operational ecosystem. Our custom integration solutions ensure data accuracy and consistency by automating data transfers and synchronizing information, reducing manual entry errors. This enhances accessibility, allowing teams to access critical data from a unified interface, fostering collaboration and improving productivity. We also implement monitoring tools to ensure optimal integration performance. Our comprehensive approach empowers your organization to optimize operations and drive business growth.",
      points: [],
      details2: "",
      link: "dataAIServices",
    },
    {
      title: "Performance optimization",
      icon: faDatabase,
      details1:
        "Our team specializes in identifying and resolving performance bottlenecks in your Dynamics 365 environment. We analyze your system to pinpoint inefficiencies and implement targeted strategies for optimization, including configuration adjustments and resource allocation. To minimize downtime, we establish monitoring and alert systems that proactively detect potential issues. Through regular updates and performance checks, we ensure a high-performing Dynamics 365 environment, enabling users to access a reliable system that enhances productivity and supports business success. With our expertise, you can maximize your Dynamics 365 investment and improve operational efficiency.",
      points: [],
      details2: "",
      link: "dataAIServices",
    },
    {
      title: "Data management support",
      icon: faArrowUpRightDots,
      details1:
        "We empower businesses with Dynamics 365 Support for data management tasks, including cleansing, migration, integration, and maintenance. Our team ensures data accuracy by removing duplicates, correcting errors, and standardizing formats. We facilitate smooth data migration to Dynamics 365 and connect it with other applications for seamless data flow. Additionally, we provide ongoing maintenance to monitor data quality and manage changes. Our support enhances data organization, enabling informed decision-making and improved operational efficiency for business growth.",
      points: [],
      details2: "",
      link: "dataAIServices",
    },
  ],
};

export const MicrosoftDynamics = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="microsoftDynamics">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <Solutions data={solutionData} />
      </Suspense>
      <Suspense>
        <BoxDetails data={boxData} />
      </Suspense>
      <Suspense>
        <ServicePointsSelect data={servicePoints} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={supportData} />
      </Suspense>
      <Suspense>
        <BoxDetails data={supportBoxData} />
      </Suspense>
      <Suspense>
        <Footer />
      </Suspense>
    </div>
  );
};

export default MicrosoftDynamics;
