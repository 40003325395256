import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";

import mainBanner from "../assets/img/main_banners/CloudWinkelpage.webp";

import detailImg from "../assets/img/CloudWinkel/AnINhousedesignedSolutionby.webp";

import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const WinkelVideo = lazy(() => import("../components/WinkelVideo"));
const BoxDetails = lazy(() => import("../components/BoxDetails"));

const mainContent = {
  heading: "Cloud Winkel",
  title: "Why Cloud Winkel?",
  para: "Cloud Winkel is a cloud based retail platform. NFIT Solutions designed Cloud Winkel to be so intuitive, anyone from owners to managers to cashiers can get the hang of it in minutes.",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading:
    "An IN-house designed  Solution by NFIT Solutions Cloud Winkel is a cloud based retail platform",
  para: `Easy to use and affordable, so no extra software or expensive servers are needed that can really saves you thousands on setup costs. Cloud Winkel is more than a POS system. We can help your business succeed, grow, and thrive in all areas.
    You’re a retailer, not a tech wizard. NFIT Solutions designed Cloud Winkel to be so intuitive, anyone from owners to managers to cashiers - can get the hang of it in minutes. You and your team can stop worrying about the tech side of things, and focus more on running and growing your business.`,
  image1: detailImg,
  points: [],
};

const boxData = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Features",
    light: false,
  },
  cards: [
    {
      title: "Point of sale",
      icon: faCartShopping,
      details1: `Cloud Winkel is web based retail platform that works on mobile, tablet and PC and syncs in the cloud so you can use one platform or all three. Continue selling even when the internet goes down, Cloud Winkel will automatically resync your sales when you’re back online. Customize receipts with your header & footer. Choose either to auto-print receipts after a sale or not. Add discounts to specific line items or to the entire sale. Add internal notes to the sale.`,
      points: [
        "Web based",
        "Works offline",
        "Custom receipts",
        "Discount & notes",
        "Easy to use",
      ],
      details2: `Cloud Winkel is incredibly intuitive and easy to use. Train your staff in minutes and get them up and selling in no time.`,
      link: "",
    },
    {
      title: "Inventory",
      icon: faNetworkWired,
      details1: `Add multiple product variants like sizes, colors, and materials and edit them all in one view. Manage pricing and markups effortlessly across all your products. Use existing barcodes or create new ones. Effortlessly add products to sales and purchase orders. See stock on hand, low stock information and inventory levels to help you make better decisions about your inventory.`,
      points: ["Variants", "Pricing", "Barcodes", "Inventory control"],
      details2: "",
      link: "",
    },
    {
      title: "Customers",
      icon: faUserPlus,
      details1: `Keep all your customer information in one place. Customer transactions are saved to their profile where you can view their purchase history and account balances. Continue adding customer to the sale even when the internet goes down, Cloud Winkel will automatically resync your customers and their sales when you’re back online.`,
      points: [
        "Customer profiles",
        "Customer purchase history",
        "Works offine",
      ],
      details2: "",
      link: "",
    },
    {
      title: "Purchase",
      icon: faCoins,
      details1: `It’s quick and easy to add and manage your purchase orders with Cloud Winkel. Effectively assign items from multiple vendors into respective purchase orders. Sales rate calculation from purchase rate, tax & any discount. Effcetive overiew of purchased items with expiry that is helpful in their timely return.`,
      points: [
        "Instant purchase",
        "Sale price calculation",
        "Purchase Returns",
      ],
      details2:
        "Cloud Winkel allows you to set reorder points to notify you when you get to a certain level of stock, helping to ensure that your customers don’t leave your store empty-handed.",
      link: "",
    },
    {
      title: "Reporting",
      icon: faChartColumn,
      details1: `Keep your finger on the pulse by accessing your key sales, product & receivable metrics from the dashboard. Quickly drill into your data to see how your store and your products are performing. Manage your inventory more effectively by keeping an eye on inventory levels, low stock information, expiring items and stock on hand. Manage your receivable by effective detailed receivable reports`,
      points: [
        "Dashboard",
        "Inventory reports",
        "Receivable reports",
        "Sale reports",
        "Tax reports",
      ],
      link: "",
    },
  ],
};

export const CLoudWinkel = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="CloudWinkel">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <WinkelVideo />
      </Suspense>
      <Suspense>
        <BoxDetails data={boxData} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default CLoudWinkel;
