import React from "react";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";

import Navbar from "../components/Navbar";

import Footer from "../components/footer";

import img6 from "../assets/img/Software_Dev/Transform_your_vision_into_reality.jpg";
import img7 from "../assets/img/Software_Dev/Software_is_becoming.jpg";

import imgCard1 from "../assets/img/flip_cards/Software_Dev/Mobile_Application.webp";
import imgCard2 from "../assets/img/flip_cards/Software_Dev/Quality_Check.webp";
import imgCard3 from "../assets/img/flip_cards/Software_Dev/devops.webp";
import imgCard4 from "../assets/img/flip_cards/Software_Dev/Tailored_Design.webp";

import mainBanner from "../assets/img/main_banners/SoftwareDevelopmentpage.webp";

import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const CardSlider = lazy(() => import('../components/CardSlider'));
const BoxDetails = lazy(() => import('../components/BoxDetails'));
const ServicePointsPage = lazy(() => import('../components/ServicePointsPage'));


const mainContent = {
  heading: "Software Development",
  title:
    "We specialize in architecting and engineering scalable, robust software solutions that seamlessly integrate with your business, ensuring it is digital-ready and drives measurable results.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  // heading : "Transform your vision into reality with our innovative software solutions, crafted through ideation, design, and development.",
  heading:
    "Transform your vision into reality with our innovative software solutions",
  para: "In today’s dynamic business landscape, strategic custom software development is imperative for success. At NFIT Solutions, we understand the importance of your distinct vision and excel at translating it into innovative solutions. Our agile methodology enables us to quickly seize market opportunities, transforming your ideas into reality. With our dedicated team overseeing every stage from concept to quality assurance, your software evolves seamlessly, keeping you ahead of the competition.",
  image1: img6,
  points: [],
};

const cardsData = {
  title_1: {
    text: "",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Mobile Application",
      link: "/mobileApplication",
      image: imgCard1,
      points: [],
      details:
        "Enhance your brand with professional app development services. We focus on user-centered design and create advanced iOS, Android, cross-platform, and web applications for various industries.",
    },
    {
      heading: "Quality Check",
      link: "/qualityAssurance",
      image: imgCard2,
      points: [],
      details:
        "We conduct thorough testing on your products to ensure top quality, encompassing manual, automated, stress, load, security, and performance testing. Our agile methodology guarantees comprehensive 360-degree quality assurance.",
    },
    {
      heading: "DevOps",
      link: "/devOpsServices",
      image: imgCard3,
      points: [],
      details:
        "Speed up software delivery through DevOps, covering assessment to automation. We eliminate barriers between teams, cultivating a culture of ongoing enhancement to ensure swift and dependable IT solutions.",
    },
    {
      heading: "Tailored Design",
      link: "/experienceDesign",
      image: imgCard4,
      points: [],
      details:
        "We meticulously test your products for quality through manual, automated, stress, load, security, and performance testing. Our agile methodology guarantees comprehensive 360-degree quality assurance.",
    },
  ],
};

const boxData = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Our Approach",
    light: false,
  },
  cards: [
    {
      title: "Strategy",
      icon: faLaptopCode,
      details1:
        "Our engineers work closely with you to understand your business objectives and requirements, developing a tailored strategy for implementing DevOps. We conduct a thorough assessment of your existing infrastructure and workflows to identify areas for improvement. By analyzing your current processes, we pinpoint opportunities for enhancement that align with your goals, ensuring a seamless integration of DevOps practices that drive efficiency, collaboration, and continuous improvement across your organization.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Implementation",
      icon: faLayerGroup,
      details1:
        "We establish automated CI/CD pipelines to significantly enhance the speed and reliability of your software delivery process. This includes the automation and optimization of building, testing, and deploying your code, ensuring a seamless workflow from development to production. Our approach incorporates best practices for version control and code quality, enabling rapid feedback loops and early detection of issues. By streamlining these processes, we help reduce manual intervention, minimize errors, and accelerate the delivery of high-quality software, allowing your teams to focus on innovation and responsiveness to market demands.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Quality Assurance",
      icon: faGear,
      details1:
        "We implement robust monitoring and logging solutions to ensure optimal performance of your software applications. This includes configuring comprehensive alerts and notifications for real-time system health and performance metrics. Our team analyzes logs to identify trends, providing valuable insights into application behavior and user interactions. By proactively addressing potential issues before they escalate, we mitigate risks and maintain high availability. Our monitoring solutions deliver actionable insights, enabling informed decision-making and continuous improvement, ensuring your software operates efficiently and consistently meets user expectations.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "After production",
      icon: faCloud,
      details1:
        "Driven by a commitment to continuous improvement, we are dedicated to helping you refine your DevOps processes over time. This involves a thorough evaluation of key metrics and stakeholder feedback to identify areas for enhancement. Using a data-driven approach, we analyze performance indicators and collaboration effectiveness to make targeted adjustments that improve efficiency and quality. Our goal is to foster a culture of ongoing improvement, empowering your teams to adapt to changing demands and consistently deliver high-quality software.",
      points: [],
      details2: "",
      link: "CloudServices",
    },
  ],
};

const listPoints = {
  heading: "Software is becoming ubiquitous in every industry.",
  image: img7,
  para: "During the pandemic and subsequent recovery, organizations are anticipated to expand their digital interactions, offerings, and services to embrace digitalization as a cornerstone of resilience and innovation. Recognizing its critical role in future achievements, global spending on digital transformation is projected to reach $3.4 trillion by 2026.",
  points: [
    { point: "Workflow efficiency" },
    { point: "Versatility and scalability" },
    { point: "Stakeholder collaboration and excellence" },
    { point: "Collective action" },
    { point: "Strategic implementation" },
  ],
};

export const SoftwareDevelopment = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="SoftwareDevelopment">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <BoxDetails data={boxData} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={listPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default SoftwareDevelopment;
