import React, { Suspense, lazy } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import ScrollToTop from "../components/ScrollToTop";

import img5 from "../assets/img/Data_and_AI/Unlock the power of AI.webp";
import img6 from "../assets/img/Data_and_AI/How can data facilitate and expedite company growth.webp";
import img7 from "../assets/img/Data_and_AI/Infinite_Potential_background.webp";

import imgCard1 from "../assets/img/flip_cards/Data_and_AI/Data_Platform_Modernization.webp";
import imgCard2 from "../assets/img/flip_cards/Data_and_AI/Enterprise_AI_Integration.webp";
import imgCard3 from "../assets/img/flip_cards/Data_and_AI/Data_Management.webp";

import mainBanner from "../assets/img/main_banners/DataandAIServicespage.webp";

import {
  faScaleBalanced,
  faHardDrive,
  faLevelUp,
  faRotate,
  faFastForward,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const CardSlider = lazy(() => import("../components/CardSlider"));
const ServiceGridPoints = lazy(() => import("../components/ServiceGridPoints"));
const ServicePointsPage = lazy(() => import("../components/ServicePointsPage"));


const mainContent = {
  heading: "Data and AI Services",
  title:
    "Enhance decision-making and uncover new opportunities with Data Analytics, AI, and ML to accelerate your digital transformation journey.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading:
    "Unlock the power of AI to fuel data-driven innovation and transformation.",
  para: "At NFIT Solutions, we leverage analytics, automation, and AI to unlock deep insights within large data sets, driving tangible business outcomes. Our dedication lies in transforming data into real value, tackling real-world problems with leading-edge proficiency and a forward-thinking strategy",
  image1: img5,
  points: [],
};

const cardsData = {
  title_1: {
    text: "",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Data Platform Modernization",
      image: imgCard1,
      points: [],
      details:
        "Leverage the potential of modern cloud-based data architecture to enhance your data's value and create advanced data products that drive informed busines Leverage the potential of modern cloud-based data architecture to enhance your data's value.",
    },
    {
      heading: "Enterprise AI Integration",
      image: imgCard2,
      points: [],
      details:
        "Our solutions enable you to create personalized customer experiences on a large scale, seamlessly integrating AI into business and IT processes to boost speed and efficiency, driving unprecedented organizational growth.",
    },
    {
      heading: "Data Management",
      image: imgCard3,
      points: [],
      details:
        "Enhance data accessibility, accuracy, and relevance to empower your organization in making informed decisions and driving growth. Enjoy streamlined data management and organization that aligns with your business objectives, paving the way for a future driven by data.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "Tap into",
    light: true,
  },
  heading_2: {
    text: "Infinite Potential",
    light: false,
  },
  back_img: img7,
  points: [
    {
      icon: faHardDrive,
      title: "Capitalize on your data's potential",
      content:
        "Capitalize on your data's potential: Leverage valuable insights to make informed decisions and open up new revenue opportunities. Optimize operations, cut expenses, and improve customer satisfaction.",
    },
    {
      icon: faScaleBalanced,
      title: "Scale innovation in products and services",
      content:
        "Harness advanced technology to innovate disruptive solutions. Speed up development, elevate customer experiences, and propel growth in competitive markets.",
    },
    {
      icon: faLevelUp,
      title: "Elevate user experiences",
      content:
        "Utilize AI and data analytics to customize interactions, anticipate needs, and surpass expectations. Increase engagement, loyalty, and satisfaction by delivering value consistently across all interactions.",
    },
    {
      icon: faRotate,
      title: "Alleviate repetitive responsibilities",
      content:
        "Utilize AI and automation to optimize workflows, reduce human errors, and allocate time to strategic initiatives. This enhances team efficiency, productivity, and job satisfaction.",
    },
    {
      icon: faFastForward,
      title: "Boost productivity",
      content:
        "Deploy AI-driven solutions to automate intricate tasks, streamline workflows, and improve decision-making. Enhance accuracy, reliability, and productivity across all areas.",
    },
    {
      icon: faListCheck,
      title: "Refine decision-making processes",
      content:
        "Utilize AI-driven analytics and data-driven insights to steer strategic planning. Equip teams with precise, up-to-date information to encourage confident and agile decision-making.",
    },
  ],
};

const points = {
  heading: "How can data facilitate and expedite company growth?",
  image: img6,
  para: "By 2023, information will be explicitly recognized as a critical enterprise asset in 90% of corporate strategies, with analytics seen as an essential competency.",
  points: [
    { point: "Abrupt Decision Making" },
    { point: "Organization Adaptability" },
    { point: "Propeller of innovation" },
    { point: "Revenue-optimizing resource" },
    { point: "Advance differentiation approach" },
    { point: "Digital-focused customer approach" },
  ],
};

export const DataAndAIServices = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="DataAndAIServices">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={points} />
      </Suspense>

      <Footer />
    </div>
  );
};

export default DataAndAIServices;
