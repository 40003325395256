import React from "react";
import Navbar from "../components/Navbar";
import { lazy, Suspense } from 'react';
import {useState , useEffect} from "react";

import Footer from "../components/footer";

import img7 from "../assets/img/Mobile_Application/Our_Approach_bg_banner.jpg";

import imgCard1 from "../assets/img/flip_cards/mobile_application/IOS_App_Development.webp";
import imgCard2 from "../assets/img/flip_cards/mobile_application/Android_App_Development.webp";
import imgCard3 from "../assets/img/flip_cards/mobile_application/Cross_Platform_App_Development.webp";
import imgCard4 from "../assets/img/flip_cards/mobile_application/App_Quality_Assurance.webp";

import mainBanner from "../assets/img/main_banners/mobileapplicationpage.webp";

import DetailImg from "../assets/img/Mobile_Application/Collaboratewithustocreateoutstandingmobileapplications.jpg";

import ScrollToTop from "../components/ScrollToTop";




import {
  faTabletScreenButton,
  faObjectGroup,
  faPersonCircleCheck,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";



const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const CardSlider = lazy(() => import('../components/CardSlider'));
const ServiceGridPoints = lazy(() => import('../components/ServiceGridPoints'));



const mainContent = {
  heading: "Mobile Application ",
  title:
    "Harness the potential of artificial intelligence, machine learning, and big data to drive your business forward through powerful digital transformation.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Collaborate with us to create outstanding mobile applications.",
  para: "Our focus lies in developing innovative, user-friendly mobile applications that push the boundaries of digital possibilities. With our skilled team, we guarantee timely delivery, superior quality, and adherence to coding standards, all aimed at driving business growth and enhancing brand reputation.",
  image1: DetailImg,
  points: [],
};

const cardsData = {
  title_1: {
    text: "Purpose Driven Mobile Application",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "IOS App Development",
      image: imgCard1,
      points: [],
      details:
        "Our team of experts excel in IOS app development crafting user centric and high quality apps for Iphone and IPAD. With technical powers and apple ecosystem combined the idea are transfored into tailor made solutions.",
    },
    {
      heading: "Android App Development",
      image: imgCard2,
      points: [],
      details:
        "Our team of Experts drive enagagement and loyality  on worlds most popular market place of Android with a lasting impact .You can elevate your brands with our tailored made Android Apps.",
    },
    {
      heading: "Cross –Platform App Development",
      image: imgCard3,
      points: [],
      details:
        "Our unified Solution can expand your brands over all reach in the market and reduce time to market by streamlining customized developments. Our team experts can deliver cost effective Apps with consistent user experience.",
    },
    {
      heading: "App Quality Assurance",
      image: imgCard4,
      points: [],
      details:
        "We build a pre user exposure by pinpointing the erros and issue in application using automated tests for seamless performance security and ensure user satisfaction.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Our Approach",
    light: false,
  },
  back_img: img7,
  points: [
    {
      icon: faTabletScreenButton,
      title: "App Consultancy",
      content:
        "Our consulting services for mobile apps focus on refining your app concept, identifying the target audience, and conducting thorough market analysis. We specialize in creating a robust development roadmap to optimize your app for success and growth in a competitive marketplace.",
    },
    {
      icon: faObjectGroup,
      title: "UI/UX Design",
      content:
        "We specialize in crafting visually appealing and user-friendly mobile app interfaces that captivate users. Our design experts adhere to user-centric principles, ensuring smooth navigation and engaging user experiences. We create interactive prototypes that allow you to visualize and enhance your app's design effectively.",
    },
    {
      icon: faPersonCircleCheck,
      title: "Customized App Developments",
      content:
        "Our team specializes in custom mobile app development for iOS, Android, and cross-platform solutions. Using cutting-edge programming languages and frameworks, we create high-quality, scalable apps that are tailored to your specific needs, enhancing user engagement and retention.",
    },
    {
      icon: faHeadset,
      title: "Maintenance & Support",
      content:
        "We offer extensive app maintenance and support services, encompassing regular updates, bug fixes, and performance optimization. Our dedicated team is always accessible to address your concerns and ensure your app stays aligned with the latest market requirements and platform updates.",
    },
  ],
};

export const MobileApplication = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="MobileApplication">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default MobileApplication;
