import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import Logo from '../assets/img/LogoNFIT_B.png';
import Logo_white from '../assets/img/LogoNFIT_W.png';
// import Logo_NFIT from '../assets/img/LogoNFIT.png';

export const Navbar = ( {getScrolled = false}) => {
  const [scrolled, setScrolled] = useState(getScrolled);
  const [visible, setVisible] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const menuItems = {
    Home: { link: '/', subItems: [] },
    Services: {
      link: '/services',
      subItems: [
        { name: "Cloud", link: 'CloudServices' },
        { name: "Data and AI", link: 'DataAIServices' },
        { name: "Software Development", link: 'softwareDevelopment' },
        { name: "Microsoft Dynamic 365 & AX2012", link: 'microsoftDynamics' },
        { name: "Resource Augmentation", link: 'resourceAugmentation' },
        { name: "Business Central", link: 'BusinessCentral' },
        { name: "Cloud Winkel", link: 'cloudWinkel' },
        { name: "Support", link: 'support' }
      ]
    },
    "Cyber Security": { link: '/cyberSecurity', subItems: [] },
    Trainings: { 
      link: '/Training', 
      subItems: [
        { name: "Supplychain Management Training" , link: 'supplyChainManagementTraining'},
        { name: "Finance Management Training" , link: 'financeManagementTraining'},
        { name: "Production Management Training" , link: 'productManagementTraining'},
        { name: "Retail Management Training" , link: 'retailManagementTraining'}
      ] 
    },
    About: { link: '/about', subItems: [] }
  };

  const subMenuItems = {
    "Software Development": [
      { name: "Mobile Application", link: 'mobileApplication' },
      { name: "Quality Assurance", link: 'qualityAssurance' },
      { name: "DevOps", link: 'devOpsServices' },
      { name: "Experience Design", link: 'ExperienceDesign' }
    ]
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
        setVisible(true);
      } else {
        setScrolled(false);
        setVisible(window.scrollY >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleServicesClick = (e) => {
    e.preventDefault();
    // Optionally show a message or handle the disabled state
  };

  return (
    <div
      className={`navbar-container ${visible ? "visible" : ""} ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <div className="navbar-logo">
        <Link to={"/"}><img src={scrolled ? Logo : Logo_white} alt="Logo" /></Link>
      </div>
      <div className="navbar-links">
        {Object.keys(menuItems).map((menu, index) => (
          <div
            className="dropdown"
            key={index}
            onMouseEnter={() => setActiveMenu(menu)}
          >
            {menu === "Services" ? (
              <Link
                className="dropbtn"
                to={menuItems[menu].link}
                onClick={handleServicesClick}
              >
                {menu}
              </Link>
            ) : (
              <Link className="dropbtn" to={menuItems[menu].link}>
                {menu}
              </Link>
            )}

                {menuItems[menu] === menuItems[activeMenu] && activeMenu && menuItems[activeMenu].subItems.length > 0 && (
                  <div
                    className={
                      subMenuItems[activeSubMenu]
                        ? "dropdown-container size-full"
                        : "dropdown-container"
                    }
                    onMouseLeave={() => {
                      setActiveMenu(null);
                      setActiveSubMenu(null);
                    }}
                  >
                    <div className="dropdown-left dropdown-left-full">
                      {menuItems[activeMenu].subItems.map((item, index) => (
                        <Link
                          key={index}
                          className="dropdown-item"
                          onMouseEnter={() => setActiveSubMenu(item.name)}
                          to={"/" + item.link}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    {subMenuItems[activeSubMenu] ? (
                      <div className="dropdown-right">
                        {subMenuItems[activeSubMenu].map((subItem, index) => (
                          <Link
                            key={index}
                            className="dropdown-subitem"
                            to={"/" + subItem.link}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    ) : null}
                  </div>
                )}
          </div>
        ))}
        <a className="contact-button" href="#Footer">
          Contact Us
        </a>
        {/* <Link className='contact-button' to={"Footer"}>Contact Us</Link> */}
      </div>

      <div
        className={` ${
          scrolled ? "hamburger-menu-green" : "hamburger-menu"
        }`}
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        ☰
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu">
          {Object.keys(menuItems).map((menu, index) => (
            <div key={index} className='mobile-menu-tabs'>
              {menuItems[menu].subItems.length > 0 ? (
                <>
                  <div className="mobile-menu-item">
                    <Link to={menu !== 'Services' ?  menuItems[menu].link : null} className='link'><p className='inter-headings-h3-light'>{menu}</p></Link>
                    <span className="material-symbols-outlined nav-dropdown-icons" 
                          onClick={() => {
                            setActiveMenu(activeMenu === menu ? null : menu);
                            setActiveSubMenu(
                              activeMenu === menu
                                ? null
                                : menuItems[menu].subItems[0].name
                            );
                          }}
                    >
                      {activeMenu !== menu ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </span>
                  </div>
                  {menuItems[menu].subItems.length > 0 && menu === activeMenu && (
                    <div className="mobile-submenu">
                      {menuItems[menu].subItems.map((item, subIndex) => (
                        <Link
                          key={subIndex}
                          className="mobile-submenu-item link"
                          to={"/" + item.link}
                        >
                          {item.name}
                          {subMenuItems[item.name]
                            ? subMenuItems[item.name].map((subItem, index) => (
                                <Link
                                  key={index}
                                  className="mobile-dropdown-subitem"
                                  to={"/" + subItem.link}
                                >
                                  {subItem.name}
                                </Link>
                              ))
                            : null}
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Link to={menuItems[menu].link} className="mobile-menu-item link">
                  <p className='inter-headings-h3-light'>{menu}</p>
                </Link>
              )}
            </div>
          ))}
          <a className="mobile-contact-button link" href="#Footer" onClick={() => setIsMobileMenuOpen(false)}>
            Contact Us
          </a>
          <button
            className="mobile-contact-button close-btn-mobile-menu"
            onClick={() => {
              setIsMobileMenuOpen(false);
              setActiveMenu(null);
              setActiveSubMenu(null);
            }}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
}

export default Navbar;
