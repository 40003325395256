import { Suspense, lazy } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import mainBanner from "../assets/img/main_banners/aboutuspage.webp";

import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const AboutUs = lazy(() => import("../components/AboutUs"));

const mainContent = {
  heading: "About Us",
  title: "Empowering Businesses with Innovative Enterprise Solutions",
  button: "Let's Connect",
  image: mainBanner,
};

export const AboutPage = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="AboutPageSection">
      <ScrollToTop />
      <Navbar />
      <Suspense >
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <AboutUs />
      </Suspense>
      <Footer />
    </div>
  );
};

export default AboutPage;
