import React from "react";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img1 from "../assets/img/MicrosoftBusinessCentral.jpg";

import img6 from "../assets/img/Microsoft_Business_Cental/Modules_of_Microsoft_Business_Central.jpg";
import img7 from "../assets/img/Microsoft_Business_Cental/Microsoft_Business_Central_Application_Across_Business_Domains.jpg";

import imgCard1 from "../assets/img/flip_cards/Microsoft_Business_Central/Implementation.webp";
import imgCard2 from "../assets/img/flip_cards/Microsoft_Business_Central/Upgradation.webp";
import imgCard3 from "../assets/img/flip_cards/Microsoft_Business_Central/Support.webp";

import mainBanner from "../assets/img/main_banners/MicrosoftBusinessCentralpage.webp";

import ScrollToTop from "../components/ScrollToTop";

import {
  faCoins,
  faWarehouse,
  faFileArchive,
  faArrowUpRightDots,
  faWrench,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const ServiceGridPoints = lazy(() => import('../components/ServiceGridPoints'));
const CardSlider = lazy(() => import('../components/CardSlider'));
const PointsCards = lazy(() => import('../components/PointsCards'));


const mainContent = {
  heading: "Microsoft Business Central",
  title:
    "Elevate Your Business: Our Expertise in Streamline and Digitally Transforming your business operations to the Next Level.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Embrace Comprehensive Control with Microsoft Business Central",
  para: "Organizational efficiency hinges on having the right data at the right time. Previously, only large enterprises could afford ERP systems that delivered these critical insights. However, with Microsoft Dynamics 365 Business Central, small and midsized businesses can now access a powerful solution that enhances current capabilities and scales with growth. Business Central accelerates growth by connecting modern workplaces with unified financial and operational data. It offers perpetual inventory management, multi-entity consolidation, and extensive operational capabilities.",
  image1: img1,
  points: [],
};

const cardsData = {
  title_1: {
    text: "",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Implementation",
      image: imgCard1,
      points: [],
      details:
        "With experience aiding over 20+ organizations in deploying Business Central software and solutions, we specialize in configuring customized solutions that streamline processes, enhance profitability, and simplify operations.",
    },
    {
      heading: "Upgradation",
      image: imgCard2,
      points: [],
      details:
        "Our team of MS Business Central solution experts collaborates closely with your teams to analyze your requirements, assess your existing setup, and deliver advanced cloud-based solutions for enterprise needs.",
    },
    {
      heading: "Support",
      image: imgCard3,
      points: [],
      details:
        "In addition to implementation, our certified professionals offer round-the-clock support to enhance organizational efficiency, promptly diagnosing and resolving system issues for seamless operations.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Modules of Microsoft Business Central",
    light: false,
  },
  back_img: img6,
  points: [
    {
      icon: faCoins,
      title: "Financial management",
      content:
        "Develop thorough financial reports to optimize business financial health and stimulate growth.",
    },
    {
      icon: faWarehouse,
      title: "Supply Chain & Warehouse Management",
      content:
        "Streamline inventory management and reduce costs with automated workflows and shipping operations.",
    },
    {
      icon: faFileArchive,
      title: "Project Management",
      content:
        "Improving project costing, accountability, and real-time insights into status, profitability, and resource utilization metrics.",
    },
    {
      icon: faArrowUpRightDots,
      title: "Sales & Marketing",
      content:
        "Boost sales and foster enduring relationships with user-friendly sales tools.",
    },
    {
      icon: faWrench,
      title: "Manufacturing",
      content:
        "Streamline bill management for material , capacity planning and Assembly management.",
    },
    {
      icon: faUserTie,
      title: "HR Management",
      content:
        "Streamlining the hiring process and monitoring employee performance efficiently using intuitive and accessible dashboards.",
    },
    {
      icon: faUsers,
      title: "Customer relation Management",
      content:
        "Drive tasks , campaigns and manage contracts using effective tools and techniques.",
    },
  ],
};

const pointsCard = {
  heading: "Microsoft Business Central Application Across Business Domains",
  back_img: img7,
  cards: [
    {
      topic: "Business Central for Finance",
      points: [
        { point: "Basic General Ledger" },
        { point: "Accounts Payable" },
        { point: "Accounts Receivable" },
        { point: "Budgets" },
        { point: "Consolidations" },
        { point: "Account Schedules" },
        { point: "Basic Fixed Assets" },
        { point: "Bank Account Management/Reconciliation" },
        { point: "Cash Flow Forecast" },
        { point: "Dimensional Accounting" },
        { point: "Inter-company Transactions" },
        { point: "Cost Accounting" },
        { point: "Document Management" },
      ],
    },
    {
      topic: "Business Central for Supply Chain",
      points: [
        { point: "Basic Inventory" },
        { point: "Basic Payables/Receivables" },
        { point: "Sales Tax/VAT" },
        { point: "Invoicing" },
        { point: "Order/Return Order Management" },
        { point: "Invoice Discounts" },
        { point: "Line Discounting/Pricing" },
        { point: "Requisition Management" },
        { point: "Location Transfers" },
        { point: "Non-Stock Items" },
        { point: "Item Tracking" },
        { point: "Bin" },
        { point: "Pick" },
      ],
    },
    {
      topic: "Business Central for Project Management",
      points: [
        { point: "Project Management" },
        { point: "Capacity Management" },
        { point: "Estimates" },
        { point: "Multiple Costs" },
        { point: "Time Sheets" },
        { point: "Revenue Recognition" },
      ],
    },
    {
      topic: "Business Central for Manufacturing",
      points: [
        { point: "Production Orders" },
        { point: "Production Bill of Materials" },
        { point: "Version Management" },
        { point: "Basic Supply/Capacity Planning" },
        { point: "Demand Forecasting" },
        { point: "Sales and Inventory Forecasting" },
      ],
    },
    {
      topic: "Business Central for Customer Relation Management",
      points: [
        { point: "Contact Management" },
        { point: "Task Management" },
        { point: "Opportunity Management" },
        { point: "Campaign Management" },
        { point: "Relationship Management" },
      ],
    },
    {
      topic: "Business Central for Human Resource",
      points: [
        { point: "Employee Cards" },
        { point: "Employee Transactions" },
        { point: "Absence Codes" },
      ],
    },
  ],
};

export const MicrosoftBusiness = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="microsoftBusiness">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <PointsCards data={pointsCard} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default MicrosoftBusiness;
