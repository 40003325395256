import './Technologies.css';


import img1 from '../assets/img/tech_icons/dynsmics365.png'
import img2 from '../assets/img/tech_icons/sqlserfver.png'
import img3 from '../assets/img/tech_icons/microsoft36.png'
import img4 from '../assets/img/tech_icons/powerbi.png'
import img5 from '../assets/img/tech_icons/microsoftbusiness.png'
import img6 from '../assets/img/tech_icons/azure.png'
import img7 from '../assets/img/tech_icons/expressjs.png'
import img8 from '../assets/img/tech_icons/angularjs.png'
import img9 from '../assets/img/tech_icons/nodejs.png'
import img10 from '../assets/img/tech_icons/mongodb.png'


const tech_icons = [
    {image : img1},
    {image : img2},
    {image : img3},
    {image : img4},
    {image : img5},
    {image : img6},
    {image : img7},
    {image : img8},
    {image : img9},
    {image : img10},
]


export const Technologies = () => {
    return(
        <div className="technologies section">
            <div className="tech-top-details">
                <h2 className='inter-headings-h2'>Technologies & Frameworks</h2>
                <hr />
                <p className='inter-details'>Our awesome Technologies we've had the pleasure to work with!</p>
            </div>
            <div className="tech-icons">
                {tech_icons.map((image,index) => 
                    <div className="technology">
                        <img src={image.image} alt="" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Technologies