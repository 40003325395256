import React, { Suspense, lazy } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img4 from "../assets/img/Cloud page/Embrace the Cloud revolution.webp";
import img5 from "../assets/img/Cloud page/Lead the Digital Revolution with the Power of the Cloud.webp";

import imgCard1 from "../assets/img/flip_cards/Cloud_Services/Cloud_Consultancy.webp";
import imgCard2 from "../assets/img/flip_cards/Cloud_Services/Cloud_Integration.webp";
import imgCard3 from "../assets/img/flip_cards/Cloud_Services/Application_Transformation.webp";

import mainBanner from "../assets/img/main_banners/cloudpage.webp";

import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const CardSlider = lazy(() => import("../components/CardSlider"));
const ServicePointsPage = lazy(() => import("../components/ServicePointsPage"));

const mainContent = {
  heading: "Cloud Services",
  title:
    "Drive digital transformation with cloud-powered solutions and pave the way for business excellence.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Embrace the Cloud revolution and unlock limitless possibilities.",
  para: "Absolutely! Here's a revised version that aims to be more engaging and impactful: \"In today's fast-paced world, digital transformation and cloud adoption are no longer options but necessities. Organizations must constantly evolve to stay competitive and relevant.Our team of experts understands this urgency and is dedicated to helping you not just keep up, but stay ahead. Specializing in end-to-end cloud solutions, we offer a comprehensive approach—from strategy to execution and optimization.Let us empower your organization to embrace the future with confidence, ensuring you're always ready to tackle whatever challenges come your way.\"",
  points: [],
  image1: img4,
};

const cardsData = {
  title_1: {
    text: "",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Cloud Consultancy",
      image: imgCard1,
      points: [],
      details:
        "We specialize in readiness assessment, cost-benefit analysis, and vendor selection, crafting a roadmap that transforms enterprises into cloud-first organizations. Our approach aligns business objectives with processes, governance, and skill development.",
    },
    {
      heading: "Cloud Integration",
      image: imgCard2,
      points: [],
      details:
        "Cloud migration and integration is a comprehensive transformation that involves a thorough evaluation of your existing IT infrastructure and asset utilization. We not only select the appropriate technology and provide support but also reframe your migration strategy to ensure effective planning and execution.",
    },
    {
      heading: "Application Transformation",
      image: imgCard3,
      points: [],
      details:
        "Managing, scaling, and maintaining legacy software can be challenging. We specialize in transforming legacy software into modern, scalable applications, ensuring your organization’s adaptability and sustainable growth.",
    },
  ],
};

const points = {
  heading: "Lead the Digital Revolution with the Power of the Cloud:",
  image: img5,
  para: "By 2024, over 45% of IT expenditures on system infrastructure, infrastructure software, application software, and business process outsourcing will transition from traditional solutions to the cloud.",
  points: [
    { point: "Explore new horizons" },
    { point: "Secure business resilience" },
    { point: "Scale with agility" },
    { point: "Encourage strategic creativity" },
    { point: "Enable unified integrations" },
  ],
};

export const CloudServices = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="CloudServices">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={points} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default CloudServices;
