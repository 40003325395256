import React from "react";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";

import Footer from "../components/footer";

import img5 from "../assets/img/environmentTesting.jpg";

import img6 from "../assets/img/Quality_Assurance/Our_Expertise.jpg";

import imgCard1 from "../assets/img/flip_cards/Quality_Assurance/System_testing.webp";
import imgCard2 from "../assets/img/flip_cards/Quality_Assurance/Performance_Viability.webp";
import imgCard3 from "../assets/img/flip_cards/Quality_Assurance/Security_testing.webp";
// import imgCard4 from '../assets/img/flip_cards/Quality_Assurance/'

import mainBanner from "../assets/img/main_banners/qualityAssurancepage.webp";

import DetailImg from "../assets/img/Quality_Assurance/OurQualityAssuranceengineers.jpg";

import ScrollToTop from "../components/ScrollToTop";

import {
  faWindowMaximize,
  faMobileAndroid,
  faGears,
  faUserPen,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";


const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const CardSlider = lazy(() => import('../components/CardSlider'));
const ServiceGridPoints = lazy(() => import('../components/ServiceGridPoints'));


const mainContent = {
  heading: "Quality Assurance",
  title:
    "We provide thorough testing and quality assurance services for software development projects of any scale. Enhance your software's quality with our comprehensive QA services.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Our Quality Assurance engineers ensure your product is flawless.",
  para: "We offer premium quality assurance services to enhance your software’s performance, security, and compatibility across platforms. Our experienced QA professionals ensure outstanding user experiences, uphold industry standards, and expedite project delivery, enabling your software to fuel business growth and enhance your brand’s reputation.",
  image1: DetailImg,
  points: [],
};

const cardsData = {
  title_1: {
    text: "Our goal is to guarantee excellence, dependability, and efficiency in every line of code.",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "System testing",
      image: imgCard1,
      points: [],
      details:
        "Our Team of experts evaluated all the features and interaction in the software and application to ensure they run flawlessly paving its way to ready to go product.",
    },
    {
      heading: "Performance Viability",
      image: imgCard2,
      points: [],
      details:
        "Detect and address bottlenecks to enhance software speed, stability, and scalability, ensuring an outstanding user experience and competitive edge.",
    },
    {
      heading: "Security testing",
      image: imgCard3,
      points: [],
      details:
        "Protect your software from threats, assess vulnerabilities, and uphold your brand's reputation in the digital realm.",
    },
    {
      heading: "Environment Testing",
      image: img5,
      points: [],
      details:
        "Achieve consistent performance across various platforms, devices, and browsers to extend your software's reach to a diverse audience.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Our Expertise",
    light: false,
  },
  back_img: img6,
  points: [
    {
      icon: faWindowMaximize,
      title: "Platform testing",
      content:
        "Ensuring application functionality and performance across diverse devices, operating systems, and browsers for a seamless and consistent user experience.",
    },
    {
      icon: faMobileAndroid,
      title: "Mobile App testing",
      content:
        "Our Mobile App Testing prioritizes functionality, compatibility, UX, and security to deliver a seamless experience across devices and operating systems. We ensure app success by maintaining high-performance standards and enhancing user engagement.",
    },
    {
      icon: faGears,
      title: "API integration",
      content:
        "Harness our expertise in API & Integration Testing to confirm smooth communication among your application's components. We validate data exchange, error handling, and security measures to fortify your backend infrastructure.",
    },
    {
      icon: faUserPen,
      title: "Advisory & Consultation",
      content:
        "Take advantage of our extensive expertise in test strategy and planning. We provide customized test advisory services to streamline your testing processes, enhance quality, and mitigate risks in your software projects.",
    },
    {
      icon: faUserTie,
      title: "QA Experts",
      content:
        "Our dedicated QA team delivers customized testing services, fostering seamless collaboration and steadfast commitment to software excellence. Utilizing diverse testing methodologies, we address varied project requirements, delivering superior quality outcomes and accelerating time-to-market to elevate your product's success.",
    },
  ],
};

export const QualityAssurance = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="qualityAssurance">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default QualityAssurance;
