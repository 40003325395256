import React, { Suspense, lazy } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import mainBanner from "../assets/img/main_banners/FinanceManagementpage.webp";

import detailImg from "../assets/img/finance_management/EnuvisionFinanceManagementTraining.webp";
import listImg from "../assets/img/finance_management/KeyFeatures.webp";

const ScrollToTop = lazy(() => import("../components/ScrollToTop"));
const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const ServicePointsPage = lazy(() => import("../components/ServicePointsPage"));


const mainContent = {
  heading: "Finance Management Training",
  title: "",
  para: "",
  button: "Book a Consultant",
  image: mainBanner,
};

const data = {
  heading: "NFIT Solutions Finance Management Training ",
  para: "NFIT Solutions Finance Management Training is designed to equip professionals with the essential skills and knowledge needed to excel in financial management roles within any organization. This comprehensive course covers key aspects of financial management, from basic principles to advanced strategies, ensuring participants gain a robust understanding of finance.",
  image1: detailImg,
  points: [],
};

const listPoints = {
  heading: "Key Features",
  image: listImg,
  para: "",
  points: [
    { point: "General Ledger" },
    { point: "Accounts Payables" },
    { point: "Accounts Receivables" },
    { point: "Bank Management" },
    { point: "Fixed Assets" },
    { point: "Capital Work in Progress (Investment Projects)" },
    { point: "Budgeting" },
    { point: "Reports" },
  ],
};

export const FinanceManagement = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="FinanceManagement">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={listPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default FinanceManagement;
