import React from "react";
import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";

import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/footer";

import img1 from "../assets/img/supplyChainManage.jpg";

import imgCard1 from "../assets/img/flip_cards/SupplyChainManagement/InventoryandWarehouseManagement.webp";
import imgCard2 from "../assets/img/flip_cards/SupplyChainManagement/TransfersProcess.webp";
import imgCard3 from "../assets/img/flip_cards/SupplyChainManagement/ProcurementManagement.webp";
import imgCard4 from "../assets/img/flip_cards/SupplyChainManagement/SaleManagement.webp";

import mainBanner from "../assets/img/main_banners/SupplyChainManagementpage.webp";

const ServiceMainPage = lazy(() => import('../components/ServiceMainPage'));
const ServiceDetailPage = lazy(() => import('../components/ServiceDetailPage'));
const CardSlider = lazy(() => import('../components/CardSlider'));


const mainContent = {
  heading: "Supply Chain Management Training",
  title: "",
  para: "",
  button: "Book a Consultant",
  image: mainBanner,
};

const data = {
  heading: "NFIT Solutions Comprehensive Supply Chain Management Training",
  para: "NFIT Solutions Comprehensive Supply Chain Management Training is designed to equip professionals with the knowledge and skills required to effectively manage and optimize supply chain operations. This training covers the end-to-end processes of supply chain management, from procurement and sourcing to inventory management, logistics, and distribution. Participants will gain a deep understanding of supply chain principles, strategies, and best practices, enabling them to drive efficiency, reduce costs, and enhance customer satisfaction within their organizations.",
  image1: img1,
  points: [],
};

const cardsData = {
  title_1: {
    text: "",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Inventory and Warehouse Management",
      link: "",
      image: imgCard1,
      points: [
        "Unit Conversion",
        "Item Group",
        "Item Model Group",
        "Storage Setup an Receiving's",
        "Inventory Location for Store & Spares Item",
        "Site, Warehouse & Location Activation and attached with Item",
        "tem Tracking & Setup Process",
      ],
      details: ``,
    },
    {
      heading: "Transfers Process",
      link: "",
      image: imgCard2,
      points: [
        "Internal transfer",
        "Intra Warehouse Transfer",
        "Business Process Description",
        "Inventory counting Process",
        "Movement Journal",
      ],
      details: ``,
    },
    {
      heading: "Procurement Management",
      link: "/devOpsServices",
      image: imgCard3,
      points: [
        "Procurement Category Hierarchy",
        "Vendor Management",
        "Purchase Requisition",
        "Purchase order Creation",
        "Return to Vendor",
        "Freight Charges",
      ],
      details: ``,
    },
    {
      heading: "Sale Management",
      link: "/experienceDesign",
      image: imgCard4,
      points: [
        "Customer Information",
        "Customer Groups",
        "Marketing Process",
        "Sales Quotation",
        "Direct Sales booking process",
        "Freight Charges Booking Process",
        "Invoice Process",
      ],
      details: ``,
    },
  ],
};


export const SupplyChainManagement = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="supplyChainManagement">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default SupplyChainManagement;
