import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import './CardSlider.css';


export const CardSlider = ({cardsData}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [timer,setTimer] = useState(0);
    const [tapped , setTapped] = useState(false);

    // var interval
    useEffect(() => {
        var interval = setInterval(()=>{
            setTimer(timer+1);
        },1000)
        if(timer >= 3 && !tapped){
            nextSlide();
            setTimer(0);
            // console.log(timer);
        }
        return () => clearInterval(interval);
    })

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cardsData.cards.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cardsData.cards.length) % cardsData.cards.length);
    };
    let noVisi = 0;

    const getVisibleCards = () => {
        const visibleCards = [];
        let noOfVisible = 3;
        if(noOfVisible > cardsData.cards.length){
            noOfVisible = cardsData.cards.length
        }
        noVisi = noOfVisible;
        for (let i = 0; i < noOfVisible; i++) {
            visibleCards.push(cardsData.cards[(currentIndex + i) % cardsData.cards.length]);
        }
        return visibleCards;
    };

    return (
        <div className="card-slider section">
            <div className="cards-slider-title">
                {cardsData.title_1.text !== "" ? (
                    <h2 className={cardsData.title_1.light ? 'inter-headings-h2-light' : 'inter-headings-h2'}>
                        {cardsData.title_1.text}
                    </h2>
                ) : null}
                {cardsData.title_2.text !== "" ? (
                    <h2 className={cardsData.title_2.light ? 'inter-headings-h2-light' : 'inter-headings-h2'}>
                        {cardsData.heading_2.text}
                    </h2>
                ) : null}
                {cardsData.para !== "" ? (
                    <p className='inter-details'>{cardsData.para}</p>
                ) : null}
            </div>
            <h2 className='inter-headings-h2'>{cardsData.title}</h2>
            <div className="slider-container">
                <button className="slider-btn left" onClick={prevSlide}><i className="fa fa-angle-left"></i></button>
                <div className="CardSliderContainer ">
                    {getVisibleCards().map((card, idx) => (
                        <div className="card" key={idx} style={{width: `${(100/noVisi)}%`}} onMouseEnter={()=>setTapped(true)} onMouseLeave={()=>setTapped(false)}>
                            <Link to={card.link}  className='link'> 
                                <div className="card-inner">
                                    <div className="card-front" style={{ backgroundImage: `url(${card.image})` }}>
                                        <div className="overlay"></div>
                                        <h3 className='inter-headings-h2'>{card.heading}</h3>
                                    </div>
                                    <div className="card-back">
                                        <h3 className='inter-headings-h3'>{card.heading}</h3>
                                        <p className='inter-details'>{card.details}</p>
                                        <div className='inter-details-list'>
                                            <ul>
                                                {card.points.map((point,idx) => (
                                                    <li>{point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                <button className="slider-btn right" onClick={nextSlide}><i className="fa fa-angle-right"></i></button>
            </div>
        </div>
    );
};

export default CardSlider;
