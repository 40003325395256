import { lazy , Suspense } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img11 from "../assets/img/Cyber_Security/Enhancing_Cybersecurity.webp";

import img12 from "../assets/img/Cyber_Security/Identify.webp";
import img13 from "../assets/img/Cyber_Security/Assess.webp";
import img14 from "../assets/img/Cyber_Security/Evaluate.webp";
import img15 from "../assets/img/Cyber_Security/Control Monitor.webp";

// import img21 from '../assets/img/Cyber_Security/The_Evolving_Threat_Landscape.jpg'
import img22 from "../assets/img/Cyber_Security/Information_Security_Awareness.webp";
import img23 from "../assets/img/Cyber_Security/EndPointEDR.webp";
import img24 from "../assets/img/Cyber_Security/Governance_Policies_Procedures.webp";
import img25 from "../assets/img/Cyber_Security/Data_Leakage_Prevention.webp";
import img26 from "../assets/img/Cyber_Security/SecurityInformation&EventMonitoringSIEMbothimages.webp";
import img27 from "../assets/img/Cyber_Security/SecurityInformation&EventMonitoringSIEMbothimages2.webp";
import img28 from "../assets/img/Cyber_Security/Compliance_with_ISO_27001_ISMS_NIST_CSF_CIS_PCI_etc.webp";
import img29 from "../assets/img/Cyber_Security/Compliance_with_ISO_27001_ISMS_NIST_CSF_CIS_PCI_etc_2.webp";

import imgCard1 from "../assets/img/flip_cards/Cyber_Security/Vulnerability_Assessments.webp";
import imgCard2 from "../assets/img/flip_cards/Cyber_Security/Our_Approach.webp";
import imgCard3 from "../assets/img/flip_cards/Cyber_Security/Benefits.webp";

import mainBanner from "../assets/img/main_banners/CyberSecuritypage.webp";

import backImgSelectPoint from "../assets/img/Cyber_Security/RiskAssessmentsbackgroundimage.webp";

import pointImg from "../assets/img/Cyber_Security/The_evolving_landscape.webp";

import resourceBackImg from "../assets/img/linesbackground/PenetrationTesting/2.png";

import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../components/ScrollToTop";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServicePointsPage = lazy(() => import("../components/ServicePointsPage"));
const BoxDetails = lazy(() => import("../components/BoxDetails"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const ServiceDetailPageRev = lazy(() =>import("../components/ServiceDetailPageRev"));
const CardSlider = lazy(() => import("../components/CardSlider"));
const ResourceTable = lazy(() => import("../components/ResourceTable"));
const CyberImageSection = lazy(() => import("../components/CyberImageSection"));
const ServicePointsSelect = lazy(() =>import("../components/ServicePointsSelect"));

const mainContent = {
  heading: "Cyber Security",
  title:
    "Safeguarding The Organization with NFIT Solutions' Cyber Security Services",
  para: "Enhance your security posture with our tailored cybersecurity services, protecting your digital assets from evolving threats and ensuring compliance",
  button: "Let's Connect",
  image: mainBanner,
};

const points = {
  heading: "The Evolving Threat Landscape",
  image: pointImg,
  para: "In today's digital age, cybersecurity has become a paramount concern for modern cities. With the increasing reliance on technology and interconnected systems, urban centers are more vulnerable than ever to cyber threats. This growing dependency exposes critical infrastructure and public safety systems to potential attacks, making robust cybersecurity measures indispensable.",
  points: [
    {
      point:
        "Cyberattacks are on the rise, targeting both public and private entities.",
    },
    {
      point:
        "Breaches can result in significant financial losses, reputational damage, and disruption of operations.",
    },
    {
      point:
        "Safe City, with its critical role in public safety, is a prime target for malicious actors.",
    },
  ],
};

const boxData = {
  topic: "Our Approach Towards ",
  topic_styled: "Securing the Organization",
  heading_1: {
    text: "Our Approach Towards",
    light: true,
  },
  heading_2: {
    text: "Securing the Organization",
    light: false,
  },
  cards: [
    {
      title: "People",
      icon: faUserPlus,
      details1:
        "Security is everyone’s responsibility, and we’re here to help you empower your team. From staff training to comprehensive cybersecurity awareness programs, we ensure your entire organization is equipped to recognize and respond to threats. Our tailored approach strengthens your team’s ability to protect sensitive information, enforce best practices, and maintain a secure environment, fostering a culture of vigilance and resilience across your business.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Process",
      icon: faClockRotateLeft,
      details1:
        "We guide you through IT best practices, helping you implement policies that prioritize cybersecurity hygiene. Our approach ensures that your organization adopts robust security measures, from policy development to daily operations, embedding cybersecurity into every aspect of your IT infrastructure. By fostering a culture of security awareness and discipline, we empower your team to protect sensitive data, mitigate risks, and maintain a resilient digital environment.",
      points: [],
      details2: "",
      link: "",
    },
    {
      title: "Technology",
      icon: faMicrochip,
      details1:
        "After establishing your people and processes, the next step is integrating the right technology into your security program. We help assess your needs, identify gaps, and implement tools that enhance your overall cybersecurity, ensuring a cohesive and effective defense against threats.",
      points: [],
      details2: "",
      link: "",
    },
  ],
};

const data1 = {
  heading: "Information Security Awareness",
  para: "We believe that a well-informed workforce is a critical component of a robust cybersecurity strategy. To this end, we educate employees on cybersecurity best practices, ensuring they are equipped to identify and avoid cyber threats. By empowering employees to become a vital line of defense against cyberattacks, we significantly enhance our overall security posture. Our comprehensive approach includes regular security awareness training sessions, where employees learn the latest techniques and strategies to protect sensitive information and recognize potential risks. These sessions are designed to foster a culture of vigilance and responsibility, making cybersecurity an integral part of our organizational ethos. Through continuous education and proactive engagement, we aim to mitigate risks and maintain a resilient security framework.",
  image1: img22,
  points: [],
};

const data2 = {
  heading: "Endpoint Security - EDR",
  para: "",
  points: [
    "Protects devices from malware, ransomware, zero-day attacks, and other threats.",
    "EDR provides real-time threat detection, investigation, and response capabilities.",
    "Enables rapid isolation and remediation of threats.",
  ],
  image1: img23,
};

// const pointsCard = {
//     heading: 'Comprehensive Cybersecurity Solutions',
//     cards: [
//         {
//             topic: 'Vulnerability Assessments',
//             points: [
//                 { point: 'Comprehensive examinations that identify, classify, and prioritize security weaknesses within your Network.' },
//             ]
//         },
//         {
//             topic: 'Our Approach:',
//             points: [
//                 { point: 'We utilize a combination of automated scanning tools to uncover vulnerabilities.' },
//                 { point: 'We categorize vulnerabilities based on severity and exploitability.' },
//                 { point: 'We provide detailed reports outlining identified vulnerabilities and recommendations for remediation.' },
//             ]
//         },
//         {
//             topic: 'Benefits:',
//             points: [
//                 { point: 'Identification of security gaps before attackers exploit them.' },
//                 { point: 'Regulatory compliance by demonstrating your commitment to information security.' },
//             ]
//         }
//     ]
// };

const cardsData = {
  // title : "Comprehensive Cybersecurity Solutions",
  title_1: {
    text: "Comprehensive Cybersecurity Solutions",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "Vulnerability Assessments",
      image: imgCard1,
      points: [],
      details:
        "Comprehensive examinations that identify, classify, and prioritize security weaknesses within your Network.",
    },
    {
      heading: "Our Approach",
      image: imgCard2,
      points: [],
      details:
        "We utilize a combination of automated scanning tools to uncover vulnerabilities. We categorize vulnerabilities based on severity and exploitability. We provide detailed reports outlining identified vulnerabilities and recommendations for remediation.",
    },
    {
      heading: "Benefits",
      image: imgCard3,
      points: [],
      details:
        "Identification of security gaps before attackers exploit them. Regulatory compliance by demonstrating your commitment to information security.",
    },
  ],
};

const resourceData = {
  heading: "Penetration Testing",
  para: "Simulates cyberattacks to uncover vulnerabilities and assess the effectiveness of your security posture.",
  back_img: resourceBackImg,
  columns: [
    {
      title: "Network Penetration Test",
      icon: faBullseye,
      points: [
        { point: "Black Box", subpoint: [] },
        { point: "White Box", subpoint: [] },
        { point: "Grey Box", subpoint: [] },
      ],
    },
    {
      title: "Wireless Penetration Test",
      icon: faLock,
      points: [
        { point: "Application", subpoint: [] },
        { point: "Security Testing", subpoint: [] },
      ],
    },
    {
      title: "Physical Penetration Test",
      icon: faUser,
      points: [
        { point: "Social Engineering", subpoint: [] },
        { point: "Remote", subpoint: [] },
        { point: "Physical", subpoint: [] },
      ],
    },
  ],
};

const data3 = {
  heading: "Governance – Policies & Procedures",
  para: `Define the overall information security strategy and objectives for Safe City. `,
  points: [
    "Clearly outline acceptable use of IT resources and user behavior expectations.",
    "Establish data classification guidelines to ensure appropriate data protection measures.",
    "Specify incident response procedures for handling security breaches and cyberattacks.",
    "Define roles and responsibilities for information security within the organization.",
  ],
  image1: img24,
};

const data4 = {
  heading: "Data Leakage Prevention (DLP)",
  para: `Security measures that prevent sensitive data from being exfiltrated from your organization's network.DLP solutions can monitor data in various formats, including documents, e-mails and USB drives.`,
  points: [
    "Prevents accidental data leaks by employees.",
    "Protects against malicious insiders attempting to steal data.",
    "Helps ensure compliance with data privacy regulations.",
    "DLP solutions utilize data classification rules to identify sensitive data.",
    "DLP monitors data transfers and enforces pre-defined security policies.",
    "DLP can prevent unauthorized data exfiltration through various methods.",
  ],
  image1: img25,
};

const servicePoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Risk Assessments",
    light: false,
  },
  backImg: backImgSelectPoint,
  points: [
    {
      title: "Identify",
      image: img12,
      content:
        "Evaluates the likelihood and potential impact of security threats to your organization.We identify your critical assets and potential security threats.",
    },
    {
      title: "Assess",
      image: img13,
      content:
        "Considers various factors, including the value of assets, vulnerabilities, and attacker motives. We assess the likelihood and potential impact of each threat.",
    },
    {
      title: "Evaluate",
      image: img14,
      content:
        "Enables informed decision-making regarding security investments. We develop a risk register that prioritizes risks based on severity.",
    },
    {
      title: "Control Monitor",
      image: img15,
      content:
        "Helps you prioritize security efforts to address the most critical risks first. ",
    },
  ],
};

const imageData1 = {
  title: "Security Information & Event Monitoring (SIEM)",
  image1: img26,
  image2: img27,
};

const imageData2 = {
  title: "Compliance with ISO 27001 ISMS, NIST CSF, CIS, PCI etc.",
  image1: img28,
  image2: img29,
};

const listPoints = {
  heading: "Enhancing Your Cybersecurity Framework",
  image: img11,
  para: "These frameworks can help organizations identify, prioritize, and manage cybersecurity risks. Helps you improve your security posture and reduce risks. Demonstrates your commitment to information security. Can give you a competitive advantage.",
  points: [
    {
      point:
        "These frameworks can help organizations identify, prioritize, and manage cybersecurity risks.",
    },
    { point: "Helps you improve your security posture and reduce risks." },
    { point: "Demonstrates your commitment to information security." },
    { point: "Can give you a competitive advantage." },
  ],
};

export const CyberSecurity = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }


  return (
    <div className="CyberSecuruty">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={points} />
      </Suspense>
      <Suspense>
        <BoxDetails data={boxData} />
      </Suspense>
      <Suspense>
        {" "}
        <ServiceDetailPage details={data1} />
      </Suspense>
      <Suspense>
        <ServiceDetailPageRev details={data2} />
      </Suspense>
      {/* <PointsCards data={pointsCard} /> */}{" "}
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ResourceTable data={resourceData} />
      </Suspense>
      <Suspense>
        <CyberImageSection data={imageData1} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data3} />
      </Suspense>
      <Suspense>
        <ServiceDetailPageRev details={data4} />
      </Suspense>
      <Suspense>
        <ServicePointsSelect data={servicePoints} />
      </Suspense>
      <Suspense>
        <CyberImageSection data={imageData2} />
      </Suspense>
      <Suspense>
        <ServicePointsPage data={listPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default CyberSecurity;
