import { useState } from "react";
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ExpandingCards.css';



export const ExpandingCards = ({Images , topDetails}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };


  const getCardStyle = (index) => {
    const totalCards = Images.length;
    const baseWidth = 100 / totalCards;
    const activeWidth = baseWidth * 2.5;
    const inactiveWidth = (100 - activeWidth) / (totalCards - 1);

    if (activeIndex === index) {
      return { width: `${activeWidth}%` };
    } else if (activeIndex !== null) {
      return { width: `${inactiveWidth}%` };
    } else {
      return { width: `${baseWidth}%` };
    }
  };




  return (
    <div className="exp-old-container">
      <h2 className="inter-headings-h2">{topDetails.heading}</h2>
      <p className="inter-details">{topDetails.para}</p>
      <div className="exp-image-container">
        {Images.map((img, index) => (
          <Link to={img.link} key={index}
          style={getCardStyle(index)}
          className={`exp-image-card-old-exp
              ${activeIndex === index ? 'active-old-exp-cards' : activeIndex != null ? 'unactive-old-exp-cards' : ''}
              ${index === currentSlide ? 'mobile-visible-old-exp-card' : ''}`
              
          }
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
          {/* <div>  */}
            <img src={img.imgPath} alt="" className="exp-image-old-exp" />
            <div className="exp-overlay-old-exp">
              {/* <div className="overlay-content-old-exp"> */}
                {activeIndex === index && (
                  <div className="text-section1">
                    <ul>
                      {img.text1 &&
                        img.text1.map((point, idx) => (
                          <li key={idx}>{point.label}</li>
                        ))}
                    </ul>
                  </div>
                )}
                <div className="exp-card-title">
                  {activeIndex === index && (
                    <FontAwesomeIcon icon={img.icon} className="exp-card-icon" />
                  )}
                  <h3
                    className={`${
                      activeIndex === index ? 'active-label inter-headings-h3' : activeIndex != null ? 'unactive-label inter-headings-h3' : 'inter-headings-h3'
                    }`}
                  >
                    {img.label}
                  </h3>
                </div>
                {activeIndex === index && (
                  <div className="text-section2">
                    <ul>
                      {img.text2 &&
                        img.text2.map((point, idx) => (
                          <li key={idx}>{point.label}</li>
                        ))}
                    </ul>
                  </div>
                )}
              {/* </div> */}
            </div>
          {/* </div> */}
          </Link> 
        ))}
        <div className="slider-controls">
        {Images.map((img, index) => (
            <button key={index} className="mobile-visible" onClick={() => {
                setCurrentSlide(index);
                setActiveIndex(index);
            }
            }>{index + 1}</button>
        ))}
      </div>
      </div>
      
    </div>
  );
};

export default ExpandingCards;
