import React, { lazy ,Suspense } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img6 from "../assets/img/Experience_Design/Our_Expertise.webp";

import imgCard1 from "../assets/img/flip_cards/experiencedesign/uiuxStrategy.webp";
import imgCard2 from "../assets/img/flip_cards/experiencedesign/uiuxdesign.webp";
import imgCard3 from "../assets/img/flip_cards/experiencedesign/WebDevelopment.webp";

import mainBanner from "../assets/img/main_banners/ExperienceDesignpage.webp";

import detailImg from "../assets/img/Experience_Design/Reimagineinnovation.webp";

import ScrollToTop from "../components/ScrollToTop";

import {
  faTimeline,
  faLightbulb,
  faCalculator,
  faEye,
  faGear,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const CardSlider = lazy(() => import("../components/CardSlider"));
const ServiceGridPoints = lazy(() => import("../components/ServiceGridPoints"));


const mainContent = {
  heading: "Experience Design",
  title:
    "Transform your business with our innovative UI/UX consulting services, crafting effortless digital experiences.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Reimagine innovation and transform your ideas into reality.",
  para: "Elevate your enterprise with outstanding products and customer experiences. Our skilled consultants craft engaging user interfaces, enhance productivity, and foster competitive advantage with data-driven design solutions.",
  image1: detailImg,
  points: [],
};

const cardsData = {
  title_1: {
    text: "Delivering top-tier UI/UX innovation in every interaction.",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "UI/UX Strategy",
      image: imgCard1,
      points: [],
      details:
        "Develop a digital strategy aligned with goals, identify areas for improvement through UX audits, establish robust information architecture, and engage users with a defined content strategy. Utilize product roadmaps and UX assessments for ongoing enhancement.",
    },
    {
      heading: "UI/UX Design",
      image: imgCard2,
      points: [],
      details:
        "Create compelling and functional interfaces across multiple devices. Design intuitive interactions, responsive layouts, and a cohesive system for consistency. Prioritize user-centered design principles to ensure user-friendly products.",
    },
    {
      heading: "Web Development",
      image: imgCard3,
      points: [],
      details:
        "Create progressive and seamless web application for better user experience and ensure future proof application with updates and performance plugins.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Our Expertise",
    light: false,
  },
  back_img: img6,
  points: [
    {
      icon: faTimeline,
      title: "UX Design Roadmap",
      content:
        "Together, we collaborate to formulate a comprehensive UX strategy that aligns with your business objectives and provides a clear roadmap for enhancing user experiences across your digital products.",
    },
    {
      icon: faLightbulb,
      title: "Research and Development",
      content:
        "Through thorough user research, we delve into your target audience's needs, preferences, and behaviors, enabling us to create precise user personas. These personas serve as the cornerstone for crafting solutions centered around the user experience.",
    },
    {
      icon: faCalculator,
      title: "UI/UX Audit",
      content:
        "We assess your current digital products to pinpoint usability issues, inefficiencies, and areas for enhancement. Subsequently, we offer practical recommendations to elevate user experiences and achieve your business goals.",
    },
    {
      icon: faEye,
      title: "Visual Design",
      content:
        "Our expertise lies in creating visually compelling and user-centric interfaces that are engaging and easy to use. We emphasize aesthetics and usability, integrating the latest design trends, user expectations, and your brand identity to ensure a cohesive experience.",
    },
    {
      icon: faGear,
      title: "Performance testing",
      content:
        "We specialize in usability testing to ensure your digital products meet user expectations and provide a rewarding experience. By gathering insights from real users, identifying potential issues, and validating design decisions, we optimize the overall user experience.",
    },
    {
      icon: faUserCircle,
      title: "Streamline UI/UX System with Guidelines",
      content:
        "We help develop and integrate design systems and UI/UX guidelines to streamline the design process, foster consistency across your products, and enhance collaboration between your design and development teams.",
    },
  ],
};

export const ExperienceDesign = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="ExperienceDesign">
      <ScrollToTop />
      <Navbar />

      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default ExperienceDesign;
