import Navbar from "../components/Navbar";
import InformationFormCareer from "../components/InformationFormCareer";
import ScrollToTop from "../components/ScrollToTop";



export const InfoPage = () => {
    return (
        <div className="InfoPage">
            <ScrollToTop />
            <Navbar getScrolled={true}/>
            <InformationFormCareer />
        </div>
    );
}

export default InfoPage