import React, { Suspense , lazy } from "react";
import {useState , useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import img1 from "../assets/img/devOps.jpg";
import img6 from "../assets/img/DevOps/DevOps_our_experties_bg_banner.webp";

import imgCard1 from "../assets/img/flip_cards/DevOpsServices/CICD.webp";
import imgCard2 from "../assets/img/flip_cards/DevOpsServices/Configurationsetup.webp";
import imgCard3 from "../assets/img/flip_cards/DevOpsServices/Bettermonitoring.webp";
import imgCard4 from "../assets/img/flip_cards/DevOpsServices/InfraAutomation.webp";

import mainBanner from "../assets/img/main_banners/DevOpsServicespage.webp";

import ScrollToTop from "../components/ScrollToTop";

import {
  faSheetPlastic,
  faWindowRestore,
  faCircleCheck,
  faTimeline,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";

const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const CardSlider = lazy(() => import("../components/CardSlider"));
const ServiceGridPoints = lazy(() => import("../components/ServiceGridPoints"));


const mainContent = {
  heading: "DevOps Services",
  title:
    "Streamline, scale, and modernize product engineering to enhance lifecycle management, workflows, and software delivery, maximizing operational efficiency.",
  para: "",
  button: "Let's Connect",
  image: mainBanner,
};

const data = {
  heading: "Enhance software deployment speed with increased success rates.",
  para: "Achieve accelerated delivery, enhance collaboration between development and operations, and optimize every phase of your software development lifecycle through our DevOps services. Our proficiency in automation and industry best practices reduces risk, eliminates duplication, integrates people, processes, and tools, ensuring resilient, adaptable software tailored to evolving business requirements.",
  image1: img1,
  points: [],
};

const cardsData = {
  title_1: {
    text: "Optimize your Software Development Journey",
    light: false,
  },
  title_2: {
    text: "",
    light: false,
  },
  cards: [
    {
      heading: "CI/CD",
      image: imgCard1,
      points: [],
      details:
        "We specialize in automating your CI/CD pipeline to ensure rapid and reliable software deployment. Our services cover tool selection, configuration, testing, deployment, and expedited time-to-market.",
    },
    {
      heading: "Configuration Setup",
      image: imgCard2,
      points: [],
      details:
        "Our DevOps experts can assist in deploying configuration solutions to automate maintenance, empower development teams, and strengthen configuration control to prevent incidents.",
    },
    {
      heading: "Better monitoring",
      image: imgCard3,
      points: [],
      details:
        "We can assist in establishing robust monitoring and logging solutions for application and infrastructure visibility, facilitating issue identification and resolution. This enhances performance and aids in detecting bugs effectively.",
    },
    {
      heading: "Infra Automation",
      image: imgCard4,
      points: [],
      details:
        "Our team specializes in automation of infrastructure by using management tools like terraform AWS Cloud  enabling scalability and reduced overhead cost for managing resources.",
    },
  ],
};

const GridPoints = {
  heading_1: {
    text: "",
    light: false,
  },
  heading_2: {
    text: "Our Expertise",
    light: false,
  },
  back_img: img6,
  points: [
    {
      icon: faSheetPlastic,
      title: "Comprehensive Planning ",
      content:
        "Starting with an assessment of your current software development process and identifying areas for enhancement, our team collaborates with you to craft a comprehensive DevOps strategy aligned with your business goals and objectives.",
    },
    {
      icon: faWindowRestore,
      title: "Framework testing",
      content:
        "We establish a pilot framework to validate the DevOps plan, setting up a scaled-down DevOps environment to test its efficacy in delivering top-notch software quality.",
    },
    {
      icon: faCircleCheck,
      title: "Implementation",
      content:
        "Let us assist you in implementing a DevOps process across your organization, ensuring comprehensive training and equipping everyone to participate effectively. We offer continuous support to ensure successful implementation and attainment of your objectives.",
    },
    {
      icon: faTimeline,
      title: "CI/CD pipeline",
      content:
        "We establish a resilient and automated CI/CD pipeline to simplify your software development workflow. This involves constructing, testing, and deploying your code, emphasizing automation and efficiency to enhance rapid and dependable software delivery.",
    },
    {
      icon: faShieldHalved,
      title: "Security",
      content:
        "Incorporate security protocols into your DevOps workflow to safeguard your software and adhere to industry regulations. This involves configuring security testing and monitoring tools, enforcing access controls, and delivering continual training to your team on best security practices.",
    },
  ],
};

export const DevOpsServices = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="DevOpsServices">
      <ScrollToTop />
      <Navbar />
      <Suspense>
        <ServiceMainPage mainContent={mainContent} />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <CardSlider cardsData={cardsData} />
      </Suspense>
      <Suspense>
        <ServiceGridPoints data={GridPoints} />
      </Suspense>
      <Footer />
    </div>
  );
};

export default DevOpsServices;
