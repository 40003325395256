import "./footer.css";
import logo from "../assets/img/LogoNFITLarge_W.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="cta-section" id="Footer">
      <div className="cta-header">
        <hr />
        <h1 className="cta-title">Innovate, Integrate, Succeed</h1>
        <Link to={"/InfoPage"} className="link"><button className="cta-talk-btn">Let's talk</button></Link>
      </div>
      <div className="cta-logo">
        <img src={logo} alt="" />
      </div>
      <div className="socials">
        <a href="https://web.facebook.com">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/nfitsolutionss/">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/feed/?trk=onboarding-landing">
          <i className="fa fa-linkedin"></i>
        </a>
      </div>
      <hr />
      <div className="cta-free-trial">
        <div className="cta-footer-right">
          <div className="resources">
            <h3>Services</h3>
            <ul>
              <Link to={"/CloudServices"} className="link">
                <li>Cloud</li>
              </Link>
              <Link to={"/DataAIServices"} className="link">
                <li>Data and AI</li>
              </Link>
              <Link to={"/SoftwareDevelopment"} className="link">
                <li>Software Development</li>
              </Link>
              <Link to={"/MicrosoftDynamics"} className="link">
                <li>Microsoft Dyanimcs</li>
              </Link>
              <Link to={"/ResourceAugmentation"} className="link">
                <li>Resource Augmentation</li>
              </Link>
              <Link to={"/BusinessCentral"} className="link">
                <li>Business Central</li>
              </Link>
              <Link to={"/CloudWinkel"} className="link">
                <li>Cloud Winkel</li>
              </Link>
              <Link to={"/Support"} className="link">
                <li>Support</li>
              </Link>
            </ul>
          </div>

          <div className="about">
            <h3>About</h3>
            <ul>
              <Link to={"/about"} className="link">
                <li>About Us</li>
              </Link>
              <Link to={"/CareerPage"} className="link">
                <li>Careers</li>
              </Link>
              <Link
                to={
                  "https://www.google.com/maps/place/75+McCabe+Drive,+Reno,+NV+89511,+USA/@39.426922,-119.7664007,17z/data=!3m1!4b1!4m6!3m5!1s0x809914e02653c1a3:0x3aeb6b86a468a328!8m2!3d39.4269179!4d-119.7638258!16s%2Fg%2F11b8v7r68f?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
                }
                className="link"
              >
                <li>Locations</li>
              </Link>
            </ul>
          </div>

          <div className="headquarters">
            <h3>Contact Details</h3>
            <p>75 Mccabe Dr, Reno, NV 89511, USA</p>
            <p>Email : info@nfitsolutions.com</p>
            <p>Phone : +1 9723527581</p>
          </div>
        </div>
      </div>

      <div className="cta-footer">
        <hr />
      </div>
    </div>
  );
};

export default Footer;
